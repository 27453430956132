import { Typography,Box, Button, CardMedia } from '@mui/material'
import React, { useEffect, useState } from 'react'
import principal from "../image/principal.jpg";
import { Prueba } from './Prueba';
import whitepaper from '../image/whitepaper.png'
import discord from '../image/discord.png'
import telegran from '../image/telegram.png'
import Modal from '@mui/material/Modal';
import { Registrar } from './Modales/Registrar';
import { rutas } from '../rutas/rutas';
import { getCookie } from '../utils/getCookies';
import { setInformacion } from '../store/slices/usuarios';
import axios from 'axios';
import { useDispatch } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs: "95%", md: 450},
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,

};

export const Inicio = () => {
   const [pagina, setPagina] = useState(0)
   const [login, setLogin] = useState(false)
   const [open, setOpen] = useState(false);
   const dispatch = useDispatch()
   useEffect(()=>{  
     

    const tokenJWT = getCookie('token')
    if(tokenJWT){



      axios.get(`${rutas.getUsuario}`, { headers: {'token': tokenJWT}})
      .then(({data})=> {
    
        dispatch(setInformacion(data))
        setPagina(3)
      })
      .catch(err=>{
        console.log(err)
          setPagina(0)
      })
      
    }

  },[])
   const handleOpen = (id) => {
     setPagina(id)
     setOpen(true);
   }
   const handleClose = () => setOpen(false);
 
   const cambio = (registro)=>{
    setLogin(registro)
    setOpen(true);
    setPagina(1)

    // setTimeout(() => {
    //   setPagina(2)

    // }, 1000);
   }
  return (
 
       <Box
       className="rpgui-content"
       sx={{
        overflow: 'auto',
         width: "100%",
         height: "100vh",
       }}
     >
      <Box sx={{ 
            
                 width: "100%",
         height: "100vh",    display: "flex",
         justifyContent: "center",
         alignItems: "start",
    
         overflow: 'hidden'}} className="rpgui-container framed">

       {(pagina === 0 || pagina === 1 )&&<Box
       className={`rpgui-container framed ${pagina === 2 ? 'magictime swashOut' : ''}`}
         sx={{
           width: "80%",
           height: "65%",
           backgroundImage: `url(${principal}) !important` ,     
           backgroundSize: 'contain !important',
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'space-around',
           flexDirection: 'column'
         }}
       >
        
       

        <Typography sx={{fontFamily: 'Minecraft', color: 'white', fontSize: '3rem !important'}}>
    City Farm
</Typography>

<Button onClick={()=>cambio(false)} class="rpgui-button" type="button"  ><p>log in</p></Button>
<Button onClick={()=>cambio(true)} class="rpgui-button" type="button"  ><p>signup</p></Button>
       </Box>}

      { pagina ===3 &&<Prueba/>}
     { pagina !==3 &&  <Box sx={{position: "fixed", bottom: 100, display: "flex"}}>
      <a target='_blank' href='https://mundo-virtual.gitbook.io/whitepaper1/'>
      <CardMedia
              className="rpgui-cursor-grab-open"
             component="img"
             image={whitepaper}
             sx={{ width: "80px", objectFit: "cover", m: '0 auto' }}
           />
             </a>
     
           <a target='_blank' href='https://discord.gg/WdSDtH8GZg'>
           <CardMedia 
     
              className="rpgui-cursor-grab-open"
             component="img"
             image={discord}
             sx={{ width: "80px", objectFit: "cover", m: '0 auto' }}
           />
           </a>


       
        
      </Box>}
     </Box>
  {  pagina !==3 && <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="rpgui-content"
      >
        <Box     className={`rpgui-container framed `} sx={style}>

        {pagina === 1&& <Registrar login={login} setPagina={setPagina} />}
        </Box>
      </Modal>}
     
     </Box>
  )
}

//magictime swashOut